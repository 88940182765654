<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-1"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <StepForm
          :loading="loading"
          :instruments="instruments"
          :entity="step"
          :read-only="readOnly"
          @update:name="step.name = $event"
          @update:description="step.description = $event"
          @update:type="step.type = $event"
          @update:defaultLevel="step.defaultLevel = $event"
          @update:parameters="step.parameters = $event"
          @update:instrumentId="step.instrumentId = $event"
          @update:duration="step.duration = $event"
          @submit="handleStep"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import StepForm from '../../components/assessments/StepForm.vue';

const stepDto = {
  name: null,
  description: null,
  // order: null,
  type: null,
  defaultLevel: null,
  duration: null,
  parameters: '{}',
  instrumentId: null,
  procedureId: null,
  hasResult: null,
};

export default {
  name: 'StepFormView',
  components: { StepForm },
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
    groupId: { type: String, required: true },
    procedureId: { type: String, default: null },
    stepId: { type: String, default: null },
  },
  data() {
    return {
      step: { id: this.stepId, ..._.cloneDeep(stepDto) },
      loading: false,
      company: {},
      group: {},
      procedure: {},
      instruments: [],
    };
  },
  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.companyName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS }, exact: true, text: 'Группы оценки' },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW }, exact: true, text: this.groupName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW }, exact: true, text: this.procedureName },
      ];

      return breadcrumbs;
    },
    companyName() {
      return this.company?.name ? this.company.name : `Компания: ${this.companyId}`;
    },
    groupName() {
      return this.group?.name ? this.group.name : `Группа оценки: ${this.groupId}`;
    },
    procedureName() {
      return this.procedure?.name ? this.procedure.name : `Процедура: ${this.groupId}`;
    },
    readOnly() {
      return this.step.id && this.step.hasResult ? [
        'type',
        'defaultLevel',
        'parameters',
        'instrumentId',
        'duration',
      ] : [];
    },
  },
  watch: {
    async stepId() {
      if (this.stepId) {
        await this.fetchStep();
      }
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const assessmentApp = new this.$di.ddd.Assessment();
        const accountsApp = new this.$di.ddd.Accounts();

        const { groupId, companyId } = this;
        const [[company], [group], [procedure], { instruments }] = await Promise.all([
          await accountsApp.services.account.getCompany({ id: companyId }),
          await assessmentApp.services.assessment.fetchGroup({ groupId }),
          await assessmentApp.services.assessment.getProcedure(
            { id: this.procedureId, groupId: this.groupId },
          ),
          await this.$di.api.Assessment.InstrumentsIndex(),
        ]);
        this.company = { ...company };
        this.group = { ...group };
        this.procedure = { ...procedure };
        this.instruments = [...instruments];

        if (this.stepId) {
          await this.fetchStep();
        }
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchStep() {
      const assessmentApp = new this.$di.ddd.Assessment();
      const [step] = await assessmentApp.services.assessment.getStep(
        { id: this.stepId, procedureId: this.procedureId },
      );
      this.step = { ...step };
    },

    async handleStep() {
      try {
        this.loading = true;
        let step;
        const assessmentApp = new this.$di.ddd.Assessment();
        const dto = _.cloneDeep(this.step);
        if (!dto.id) {
          [step] = await assessmentApp.services.assessment.createStep(dto, this.procedureId);
        } else {
          [step] = await assessmentApp.services.assessment.updateStep(dto, this.procedureId);
        }
        const msg = dto.id ? `Шаг ${dto.name} отредактирован` : `Шаг ${dto.name} добавлен`;
        this.$di.notify.snackSuccess(msg);
        this.$router.push({
          name: this.Names.R_LEARNING_ASSESSMENT_STEP_VIEW,
          params: {
            stepId: step.id,
          },
        });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW });
    },
  },
};
</script>
