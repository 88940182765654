<template>
  <VForm>
    <VContainer fluid>
      <VRow align="center">
        <VCol cols="12">
          <h1 class="tt-text-headline-1">
            {{ normalizedTitle }}
          </h1>
        </VCol>
      </VRow>
      <VRow align="center">
        <VCol cols="6">
          <TTTextField
            :value="entity.name"
            label="Название"
            placeholder="Введите название"
            :disabled="loading"
            :loading="loading"
            @input="$emit('update:name', $event)"
          />
        </VCol>
      </VRow>
      <VRow align="center">
        <VCol cols="6">
          <TTTextarea
            :value="entity.description"
            label="Описание"
            placeholder="Введите описание шага"
            :disabled="loading"
            :loading="loading"
            @input="$emit('update:description', $event)"
          />
        </VCol>
      </VRow>
      <VRow align="center">
        <VCol cols="3">
          <TTAutocomplete
            :value="entity.type"
            :items="types"
            :filter="customFilter"
            item-text="name"
            item-value="id"
            label="Тип"
            placeholder="Выберите тип"
            :disabled="isReadOnly('type') || loading"
            :loading="loading"
            @input="$emit('update:type', $event)"
          />
        </VCol>

        <VCol cols="3">
          <TTAutocomplete
            :value="entity.instrumentId"
            :items="instruments"
            :filter="customFilter"
            item-text="name"
            item-value="id"
            :disabled="disabledInstruments || loading"
            :loading="loading"
            label="Инструмент"
            placeholder="Выберите инструмент"
            @input="$emit('update:instrumentId', $event)"
          />
        </VCol>
      </VRow>

      <VRow align="center">
        <VCol cols="3">
          <TTAutocomplete
            :value="entity.defaultLevel"
            :items="levels"
            :filter="customFilter"
            item-text="name"
            item-value="id"
            label="Уровень"
            placeholder="Выберите уровень"
            :disabled="isReadOnly('defaultLevel') || loading"
            :loading="loading"
            @input="$emit('update:defaultLevel', $event)"
          />
        </VCol>

        <VCol cols="3">
          <TTTextField
            :value="entity.duration"
            type="number"
            label="Продолжительность"
            placeholder="Введите продолжительность"
            :disabled="isReadOnly('duration') || loading"
            :loading="loading"
            @input="$emit('update:duration', $event)"
          />
        </VCol>
      </VRow>

      <VRow align="center">
        <VCol cols="6">
          <TTTextarea
            :value="entity.parameters"
            label="Параметры JSON"
            placeholder="Введите параметры"
            :disabled="isReadOnly('parameters') || loading"
            :loading="loading"
            @input="$emit('update:parameters', $event)"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTBtn
            color="tt-secondary"
            :disabled="loading"
            @click="handleCancel"
          >
            Отмена
          </TTBtn>

          <TTBtn
            class="ml-3"
            :disabled="loading"
            @click="handleSubmit"
          >
            {{ normalizedSubmit }}
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </VForm>
</template>

<script>

export default {
  name: 'StepForm',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    instruments: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      default() {
        return [
          { id: 'STEP_TYPE_INVALID', name: 'STEP_TYPE_INVALID' },
          { id: 'STEP_TYPE_ASSESSMENT', name: 'STEP_TYPE_ASSESSMENT' },
          { id: 'STEP_TYPE_VERIFICATION', name: 'STEP_TYPE_VERIFICATION' },
          { id: 'STEP_TYPE_PEER_ASSESSMENT', name: 'STEP_TYPE_PEER_ASSESSMENT' },
          { id: 'STEP_TYPE_LEARNING', name: 'STEP_TYPE_LEARNING' },
        ];
      },
    },
    levels: {
      type: Array,
      default() {
        return [
          { id: 'LEVEL_INVALID', name: 'LEVEL_INVALID' },
          { id: 'LEVEL_NOVICE', name: 'LEVEL_NOVICE' },
          { id: 'LEVEL_BASIC', name: 'LEVEL_BASIC' },
          { id: 'LEVEL_PROFICIENT', name: 'LEVEL_PROFICIENT' },
          { id: 'LEVEL_ADVANCED', name: 'LEVEL_ADVANCED' },
          { id: 'LEVEL_EXPERT', name: 'LEVEL_EXPERT' },
        ];
      },
    },
    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      parentTeamCheckbox: false,
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Добавление шага';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование шага';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить шаг';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
    disabledInstruments() {
      return this.isReadOnly('instrumentId')
        || !['STEP_TYPE_ASSESSMENT', 'STEP_TYPE_PEER_ASSESSMENT'].includes(this.entity.type);
    },
  },

  watch: {
    'entity.type': function handler() {
      if (this.disabledInstruments) {
        this.$emit('update:instrumentId', null);
      }
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit', this.entity);
    },
    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
